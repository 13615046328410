export function authHeader() {
    let user = JSON.parse(localStorage.getItem('zlUsr'));

    if( user && user.auth_token) {
        //return { 'Authorization': 'Token eyJhbGciOiJIUzUxMiIsImlhdCI6MTU4MTM5OTUyNCwiZXhwIjoxNTgzOTkxNTI0fQ.eyJpZCI6MX0.26YNG3mqrJbobA9ALZY9wjpivfJ4HXLnmelrZ-W-h6WF15VBrnl9992P9vC1HcDWZUiV3s3hnOo-DlOVhGy8Jw' };
        return { 'Authorization': 'Token '+ user.auth_token };
    }
    else {
        return {};
    }
}